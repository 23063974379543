export const ENV = {
  BASE_URL:process.env.GATSBY_TTF_BASE_URL,
  API_BASE: process.env.GATSBY_TTF_PUBLIC_API_BASE,
  X_SOURCE_ORIGIN: process.env.GATSBY_TTF_PUBLIC_X_SOURCE_ORIGIN,
  CLIENT_ID: process.env.GATSBY_TTF_CLIENT_ID,
  CLIENT_SECRET: process.env.GATSBY_TTF_CLIENT_SECRET,
  BRAND_SLUG: process.env.GATSBY_TTF_BRAND_SLUG,
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_TTF_STRIPE_PUBLISHABLE_KEY,
  TTF_ACCESS: process.env.GATSBY_TTF_ACCESS || "manatickets",
  MANA_ENVIRONMENT: process.env.GATSBY_MANA_ENVIRONMENT,
  MANA_TTF_SSO_CLIENT: process.env.GATSBY_MANA_TTF_SSO_CLIENT,
  MANA_TTF_SSO_SECRET: process.env.GATSBY_MANA_TTF_SSO_SECRET,
  MANA_SECRET: process.env.GATSBY_MANA_SECRET,
  MANA_CLIENT: process.env.GATSBY_MANA_CLIENT,
  APP_ID: process.env.GATSBY_TTF_APP_ID,
}