import { CreatePassword, ForgotPassword, Login, LoginWithEmail, SignUp, VerificationCode, useConfig as getConfig } from '@manacommon/web-auth'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ForgotPasswordModal, useCookieListener } from 'tf-checkout-react'

import { getCookieByName, setCustomCookie } from '../../utils/cookies'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Footer from '../footer'
import Header, { X_TF_ECOMMERCE } from '../header'
import './style.scss'
import { useSignUp } from '@manacommon/web-auth/src/hooks';
import { ENV } from '../../constants/env'

setTfCheckoutReactConfigs()

export const customStyles = {
  content: {
    background: 'black',
    position: 'unset',
  },
}

const { setConfiguration } = getConfig();

setConfiguration({
  ENVIRONMENT: ENV.MANA_ENVIRONMENT,
  TF_SSO_CLIENT: ENV.MANA_TTF_SSO_CLIENT,
  TF_SSO_SECRET: ENV.MANA_TTF_SSO_SECRET,
  SECRET_KEY: ENV.MANA_SECRET,
  CLIENT: ENV.MANA_CLIENT,
  ENABLE_TF_AUTHENTICATION: true,
  TIMEOUT: 50000
});

const Layout = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false)
  const [modalName, setModalName] = useState('login')
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [errorMsg, setError] = useState(null)
  const isWindowDefined = typeof window !== 'undefined'
  const [isLogged, setIsLogged] = useState(isWindowDefined ? !!getCookieByName(X_TF_ECOMMERCE) : false)

  useCookieListener(X_TF_ECOMMERCE, (value) => setIsLogged(Boolean(value)))

  const onClose = () => {
    setShowLogin(false)
    setModalName('login')
    setError(null)
  }

  const handleOnLogin = (e) => {
    if (e?.tfResponseHeaders?.['x-tf-ecommerce']) {
      setCustomCookie("X-TF-ECOMMERCE", e.tfResponseHeaders['x-tf-ecommerce'])
    }
    setShowLogin(false)
    setIsLogged(true)
    setError(null)

    if (isWindowDefined) {
      if (window.location.pathname?.includes('billing')) {
        window.location.reload()
      }
    }
  }

  const handleGoToLoginWithEmail = () => {
    setModalName('withEmail')
    setError(null)
  }

  const handleGoToSignUp = () => {
    setModalName('signUp')
    setError(null)
  }

  const handleToForgotPassword = () => {
    setModalName('forgotPassword')
    setError(null)
  }

  const handleToLogin = () => {
    setModalName('withEmail')
    setError(null)
  }

  const onLoginError = (e) => {
    setError(e?.response?.data?.message || '')
  }

  const onConfirm = () =>{
    setModalName("withEmail")
    setError(null)
  }

  const onSendEmail = (email) => {
    setModalName("verificationCode")
    setEmail(email)
    setError(null)
  }

  const onClickBack = () => {
    setModalName("withEmail")
    setError(null)
  }

  const onSubmitSignup = () => {
    setModalName('withEmail')
    setError(null)
    toast.success("Registration was successful! Check your email to confirm your email address.")
  }

  const onErrorSignup = (error) => {
    setError(error?.response?.data?.title)
  }

  const onCompleteVerification = (code) => {
    setCode(code)
    setModalName("createPassword");
    setError(null)
  }

  const verificationBack = () => {
    setModalName('forgotPassword')
    setError(null)
  }

  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")

  const renderModal = () => {
    const styles = {
      root: { container: { background: 'black' }, backButtonWrapper: { width: 30 } },
      centerContainer: { background: 'black' },
      title: { color: 'white' },
    }
    const stylesLogin = { root: { container: { background: 'black' } } }
    const stylesLoginWithEmail = {
      root: { container: { background: 'black' } },
      loginBoxWrapper: { background: 'black' },
      title: { color: 'white', marginBottom: 16 },
      footer: { color: 'white' },
    }
    switch (modalName) {
      case 'withEmail': {
        return (
          <LoginWithEmail
            onLoginSuccess={handleOnLogin}
            handleLoginWithEmail={handleGoToLoginWithEmail}
            onForgotPassword={handleToForgotPassword}
            onSignUp={handleGoToSignUp}
            onLoginError={onLoginError}
            styles={stylesLoginWithEmail}
          />
        )
      }
      case 'signUp': {
        return (
          <SignUp
            onNavigateSignIn={handleToLogin}
            onError={onErrorSignup}
            onSubmit={onSubmitSignup}
            styles={stylesLogin}
            externalRegisterEmailLink={window.location.origin}
            onClickTerms={() => window.open('https://www.ticketfairy.com/terms-of-service/', '_blank')}
          />
        )
      }
      case 'forgotPassword': {
        return (
          <ForgotPassword
            styles={styles}
            showBackButton
            onClickBack={onClickBack}
            onSendEmail={onSendEmail}
          />
        )
      }
      case 'verificationCode': {
        return (
          <VerificationCode
            styles={styles}
            onComplete={onCompleteVerification}
            userEmail={email}
            showBackButton
            onClickBack={verificationBack}
          />
        )
      }
      case 'createPassword': {
        return (
          <CreatePassword
            styles={styles}
            email={email}
            code={code}
            onConfirm={onConfirm}
          />
        )
      }
      default: {
        return (
          <Login
            onLoginSuccess={handleOnLogin}
            handleLoginWithEmail={handleGoToLoginWithEmail}
            handleSignUp={handleGoToSignUp}
            styles={stylesLogin}
          />
        )
      }
    }
  }

  const { confirmEmail } = useSignUp();
  const urlParams = new URLSearchParams(isWindowDefined ? window.location.search : '');
  const userId = urlParams.get('userId');
  const token = urlParams.get('verificationToken');
  const [isLoading, setIsLoading] = useState(!!userId);

  useEffect(() => {
    const completeRegister = async () => {
      if (userId !== null && token !== null) {
        try {
          await confirmEmail(userId, token);
          toast.success("Email confirmation completed!")
          setIsLoading(false);
        } catch {
          toast.error("Error with confirmation email!")
          setTimeout(() => {
            setIsLoading(false);
          }, 3000)
        }
      }
    };
    completeRegister();
  }, [userId]);

  return (
    <>
      <Header setShowLogin={setShowLogin} setIsLogged={setIsLogged} isLogged={isLogged} />
      <main className="main-layout-content">
        {showLogin && !isLogged && (
          <>
            <Modal  isOpen={showLogin} onRequestClose={onClose} style={customStyles} contentLabel="Example Modal">
              {renderModal()}
              <div style={{ textAlign: 'center', margin: '10px 0 0' }}>
                {errorMsg ? <p className="error-text">{errorMsg}</p> : <div style={{ height: 20 }} />}
                <button className="close-btn" onClick={onClose}>
                  Close
                </button>
              </div>
            </Modal>
          </>
        )}
        {showResetPasswordModal && (
          <ForgotPasswordModal
            onClose={() => {
              setShowResetPasswordModal(false)
            }}
            onLogin={() => {
              setShowResetPasswordModal(false)
              setShowLogin(true)
            }}
            onForgotPasswordSuccess={() => {
              setShowResetPasswordModal(false)
              setShowLogin(true)
            }}
          />
        )}
        {isLoading ? <div className='layout-loading'>
          <div className="redirect-content">Checking email</div>
          <div className="animation-dots" />
        </div> : children}
      </main>
      <ToastContainer autoClose={10000} />
      <Footer />
    </>
  )
}

export default Layout
