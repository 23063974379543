import React, { useEffect, useState } from "react"
import facebook from '../../assets/images/facebook.svg'
import messenger from '../../assets/images/messenger.svg'
import email from '../../assets/images/email.svg'
import linkedin from '../../assets/images/linkedin.svg'
import twitter from '../../assets/images/twitter.svg'
import { ENV } from "../../constants/env"

export const ShareButtons = ({ name }) => {
  const [domain, setDomain] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setDomain(window.location.href)
    const getIsMobile = () => !!(
      window.navigator.userAgent.match(/Android/i) ||
      window.navigator.userAgent.match(/webOS/i) ||
      window.navigator.userAgent.match(/iPhone/i) ||
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPod/i) ||
      window.navigator.userAgent.match(/BlackBerry/i) ||
      window.navigator.userAgent.match(/Windows Phone/i)
    )
    const isMobile = getIsMobile()
    setIsMobile(isMobile)
  }, [])
  return <div>
    <div className="social-media-icons-title">Share with Friends</div>
    <div className="social-media-icons-box">
      <a href={domain ? "https://www.facebook.com/sharer/sharer.php?u=" + domain : ''} target="_blank">
        <img src={facebook} alt='' height={"24px"} />
      </a>
      <a href={domain
        ? isMobile
          ? "fb-messenger://share?link=" + domain
          : `http://www.facebook.com/dialog/send?app_id=${ENV.APP_ID}&link=${domain}&redirect_uri=${domain}`
        : ''} target="_blank">
        <img src={messenger} alt='' height={"24px"} />
      </a>
      <a href={domain ? "https://www.linkedin.com/sharing/share-offsite/?url=" + domain : ''} target="_blank">
        <img src={linkedin} alt='' height={"24px"} />
      </a>
      <a href={domain ? "https://twitter.com/intent/tweet?url=" + domain : ''} target="_blank">
        <img src={twitter} alt='' height={"24px"} />
      </a>
      <a href={domain ? `mailto:?subject=Check out ${name}&body=${domain}.` : ''}>
        <img src={email} alt='' height={"30px"} />
      </a>
    </div>
  </div>
}